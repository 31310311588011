@import "../../styles/variables.scss";

.action-container > :last-child {
  margin-left: 8px;
}

.rating-img {
  margin: 0 0 1px 5px;
}

.no-brands-div {
  height: 70vh;
  p {
    margin-left: -40px;
  }
}

.chip-label {
  padding-top: 1px;
  background: #eaeaea;
  &[data-checked][data-variant="filled"] {
    background: #434343;
    color: #ffffff;
    &:hover {
      background: #434343;
      color: #ffffff;
    }
  }
  .chip-icon {
    color: #ffffff;
  }
}

.w-500 {
  width: 500px;
}

.pagination-item {
  &[data-active] {
    background: #000000;
    color: #ffffff;
  }
}

.w-65 {
  width: 65px;
}

.min-h-70 {
  min-height: 65vh;
}
