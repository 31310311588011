@import "../../styles/variables";

.table {
  tbody {
    tr {
      td {
        min-width: calc(100% / 6);
        &:last-of-type {
          width: min-content;
        }
      }
    }
  }
}
.selected-tab {
  color: $color-grey;
}
.selected-tab[data-active="true"] {
  color: $color-primary !important;
}

.bullet-marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color-primary;
}

.bullet[data-active] {
  background-color: white !important;
  border: 2px solid $color-grey-light;
}
.line[data-line-active]::before {
  border-left-color: $color-grey-light;
  top: 36px;
  bottom: -20px;
}

.action-buttons {
  position: absolute;
  top: 30px;
  margin: 0 80px;
  right: 0;
}
