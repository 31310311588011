@import "../../styles/variables";

.bullet-marker {
  width: 16px;
  height: 16px;
  background-color: #fff;
}

.bullet[data-with-child] {
  background-color: #fff !important;
  border: none;
}
.line[data-line]::before {
  border-left-color: black;
  top: 36px;
  bottom: -20px;
}

.end-bullet-marker {
  width: 6.5px;
  height: 6.5px;
  border-radius: 50%;
  background-color: black;
}

.card {
  width: 40vw;
  // min-width: 362px;
}
li::marker {
  color: #f0792e;
}
