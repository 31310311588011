.modal-content {
    width: 600px;
    .product-details {
        margin-bottom: 10px;

        div {
            margin-bottom: 5px;
        }
    }

    .breakup-details,
    .settlement-details {
        margin-top: 20px;

        h3 {
            margin-bottom: 10px;
        }

        div {
            margin-bottom: 5px;
        }
    }
}