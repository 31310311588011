.no-offers-div {
  height: 70vh;
  p {
    margin-left: -40px;
  }
}

.remove-modal-footer {
  button {
    width: 40%;
  }
}

.validity-container {
  background: rgba(0, 0, 0, 0.04);
}

.w-400 {
  width: 400px;
}

.bg-lightgray {
  background: #f5f5f5;
}

.level-details-container {
  .brand-name {
    width: max-content;
  }
}

.detail-modal-footer {
  .action-btn-container > :first-child {
    margin-right: 5px;
  }
}

.w-120 {
  width: 120px;
}

.w-250 {
  width: 250px;
}

.w-max {
  width: max-content;
}

.mw-540 {
  max-width: 540px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 20ch;
  cursor: pointer;
}

.ellipsis:hover {
  overflow: visible;
  white-space: wrap;
}
