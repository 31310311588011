.dropzone-error {
    border-color: red !important;
}

.clr-red {
    color: red;
    text-align: center;
}

.circleUpload{
    width:120px;
    height: 120px;
    border-radius: 50%;
    background-color: rgba(85, 74, 240, 0.10);;
}
.images{
    justify-content: center;
    align-items: center;
    display: flex;
    top:30%;
    position: relative;

}