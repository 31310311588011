.login-pg-container {
    height: 100vh;
    .login-main {
        padding: 74px 120px 0 120px;
        .h-80 {
            height: 80px;
        }
        .input-wrapper {
            width: 100%;
            .input-icon {
                margin-top: 1px;
            }
            .input-label {
                height: 55px;
            }
        }
        .continue-btn {
            width: 100%;
            padding: 19px 0;
            height: fit-content;
        }
        .back-btn-container {
            width: 100%;
        }
        .empty-div {
            height: 100px;
        }
    }
    .hero-img {
        // flex: 3;
        object-fit: cover;
    }
}
