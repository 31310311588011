@import "./variables.scss";

@for $i from 12 through 40 {
  .txt-#{$i} {
    font-size: 1px * $i;
  }
}

@for $i from 1 through 9 {
  .txtw-#{$i} {
    font-weight: 100 * $i;
  }
}

@for $i from 1 through 50 {
  .gap-#{$i} {
    gap: 1px * $i;
  }
}

@for $i from 1 through 150 {
  .m-#{$i} {
    margin: 1px * $i;
  }
  .mt-#{$i} {
    margin-top: 1px * $i;
  }
  .mb-#{$i} {
    margin-bottom: 1px * $i;
  }
  .ml-#{$i} {
    margin-left: 1px * $i;
  }
  .mr-#{$i} {
    margin-right: 1px * $i;
  }
  .mx-#{$i} {
    margin-left: 1px * $i;
    margin-right: 1px * $i;
  }
  .my-#{$i} {
    margin-top: 1px * $i;
    margin-bottom: 1px * $i;
  }
}

@for $i from 1 through 150 {
  .p-#{$i} {
    padding: 1px * $i;
  }
  .px-#{$i} {
    padding-left: 1px * $i;
    padding-right: 1px * $i;
  }
  .py-#{$i} {
    padding-top: 1px * $i;
    padding-bottom: 1px * $i;
  }
  .pt-#{$i} {
    padding-top: 1px * $i;
  }
  .pb-#{$i} {
    padding-bottom: 1px * $i;
  }
  .pl-#{$i} {
    padding-left: 1px * $i;
  }
  .pr-#{$i} {
    padding-right: 1px * $i;
  }
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-stretch {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-even {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-stretch {
  align-items: stretch;
}

.align-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}
@for $i from 1 through 12 {
  .flex-#{$i} {
    flex: $i;
  }
}

.grid {
  display: grid;
}

.items-justify-stretch {
  justify-items: stretch;
}

@for $i from 2 through 8 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }
}

.gcol-start-1 {
  grid-column-start: 1;
}

.gcol-end-3 {
  grid-column-end: 3;
}

.gcol-end-7 {
  grid-column-end: 7;
}

.col-gap-5 {
  column-gap: 5px;
}

.col-gap-40 {
  column-gap: 40px;
}

.row-gap-25 {
  row-gap: 25px;
}

.row-gap-10 {
  row-gap: 10px;
}

.grid-rowh-75 {
  grid-auto-rows: 75px;
}

.bg-white {
  background: #ffffff;
}

.bg-primary {
  background: $color-primary;
}

.clr-white {
  color: #ffffff;
}

.clr-black {
  color: #000000;
}
.clr-green {
  color: $color-success;
}

.clr-grey {
  color: $color-grey;
}

.clr-grey-light {
  color: $color-grey-light;
}
.clr-grey-dark {
  color: $color-grey-dark;
}

.clr-primary {
  color: $color-primary;
}

.txt-ucase {
  text-transform: uppercase;
}

.txt-cap {
  text-transform: capitalize;
}

@for $i from 5 through 20 {
  .brd-#{$i} {
    border-radius: 1px * $i;
  }
  .brd-tl-#{$i} {
    border-top-left-radius: 1px * $i;
  }
  .brd-tr-#{$i} {
    border-top-right-radius: 1px * $i;
  }
  .brd-bl-#{$i} {
    border-bottom-left-radius: 1px * $i;
  }
  .brd-br-#{$i} {
    border-bottom-right-radius: 1px * $i;
  }
}

.brd-circle {
  border-radius: 50%;
}

.bd-full {
  border: solid thin #eaecf0;
}

.bd-btm {
  border-bottom: solid thin #eaecf0;
}

.bd-top {
  border-top: solid thin #eaecf0;
}

.bd-left {
  border-left: solid thin #eaecf0;
}

.bd-right {
  border-right: solid thin #eaecf0;
}

.bd-gray {
  border: solid thin #eaecf0;
}

.cursor-ptr {
  &:hover {
    cursor: pointer;
  }
}
.cursor-default {
  cursor: default;
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}

.no-display {
  display: none;
}

.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.h-half {
  height: 50%;
}
.h-full {
  height: 100%;
}

@for $i from 5 through 1000 {
  .w-#{$i} {
    width: 1px * $i;
  }
  .h-#{$i} {
    height: 1px * $i;
  }
  .min-w-#{$i} {
    min-width: 1px * $i;
  }
  .min-h-#{$i} {
    min-height: 1px * $i;
  }
  .max-w-#{$i} {
    max-width: 1px * $i;
  }
  .max-h-#{$i} {
    max-height: 1px * $i;
  }
}

.w-fit-content {
  width: fit-content;
}

.no-underline {
  text-decoration: none;
}

.pg-container {
  height: 100vh;
  overflow: hidden;
  .pg-main {
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background: white;
    .pg-subheader {
      .pg-subheader-btn {
        height: 40px;
      }
    }
    .no-content-div {
      div {
        background: rgba(0, 0, 0, 0.02);
        img {
          width: 80px;
        }
      }
    }
  }
}

.section-header {
  margin: 30px 80px 0;
  color: $color-grey-dark;
  font-weight: 600;
  font-size: 30px;
}
