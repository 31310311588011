@import "../../../styles/variables.scss";

.nav-root {
  &[data-active] {
    background: white;
    color: black;
    &:hover {
      background: #f7f7f7;
    }
  }
}

.nav-body {
  font-size: 14px;
  font-weight: 600;
  color: $color-grey;
  width: max-content;
  padding: 10px 0;
}

.nav-parent {
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: #f7f7f7;
  }

  p {
    width: max-content;
    text-transform: capitalize;
  }
  .nav-child {
    position: absolute;
    top: 110%;
    z-index: 1;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    .link-child {
      font-size: 14px;
      color: #434343;
      font-weight: 500;
      text-transform: capitalize;
    }
    .link-child-active {
      color: black;
    }
  }
}

.nav-parent-active {
  // border-bottom: 2px solid black;
  p {
    color: black;
  }
}

.tab-label-active {
  color: black;
}

.marker,
.tab-active-marker {
  height: 8px !important;
  width: 8px !important;
  margin-right: 10px;
  border-radius: 50%;
}

.green,
.tab-active-marker {
  background-color: $color-success;
}
.red {
  background-color: $color-failure;
}
.primary {
  background-color: $color-primary;
}
