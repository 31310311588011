.orderDetails {
  margin: 30px 80px 0;

  .detailsBanner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 16px 20px 16px 1px;
    align-items: center;
    justify-content: start;
    gap: 24px;
    align-self: stretch;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .detailsCardContainer {
    padding: 0 20px 0 1px;
    display: grid;
    grid-template-columns: repeat(4, 0.5fr);
    justify-content: start;
    gap: 24px;
    .detailsCard {
      display: flex;
      padding: 24px;
      flex-direction: column;

      gap: 20px;
      flex: 1 0 0;
      border-radius: 12px;
      border: 1px solid var(--Gray-200, #eaecf0);
      background: var(--Base-White, #fff);

      /* Shadows/shadow-xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    .description {
      color: var(--Gray-600, #475467);

      /* Text sm/Medium */
      // font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .subHeading {
      color: var(--Gray-900, #101828);

      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 150% */
    }
  }
  .productDetailsCardContainer {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  .productsDetailsContainer {
    .detailsDescription {
      font-weight: 600;
      font-size: 18px;
    }
    .productsTable {
      tbody {
        tr {
          td {
            width: calc(60% / 3);
            &:first-of-type {
              width: 40%;
            }
          }
        }
      }
    }
  }
}
