@import "../../../styles/variables";

.filled-sec-btn {
  background: #ffffff;
  color: #3a3a3a;
  &:hover {
    background: #ffffff;
  }
}
.subtle-btn {
  &:hover {
    background: $color-primary-transparent;
  }
}
.default-btn {
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 500;
  .btn-img {
    margin-bottom: 1px;
  }
}
