.ecom-table {
  tbody {
    tr {
      td {
        min-width: calc(100% / 7);
        &:last-of-type {
          width: min-content;
        }
      }
    }
  }
}

.mobility-table {
  tbody {
    tr {
      td {
        min-width: calc(100% / 5);
        &:last-of-type {
          width: min-content;
        }
      }
    }
  }
}
