@import "../../styles/_variables.scss";
@import "../../styles/_common.scss";

.notif-row {
  background: $color-grey-dark;
  border: solid thin $color-grey-dark;
}

.no-outlets-div {
  height: 70vh;
  p {
    margin-left: -40px;
  }
}

.dropzone-txt {
  border: dashed thin #d9d9d9;
  width: fit-content;
}

.w-650 {
  width: 650px;
}

.w-550 {
  width: 550px;
}

.w-150 {
  width: 150px;
}

.checked-offer {
  border-color: $color-primary;
  border-left-width: 5px;
}

.map-container {
  height: 60vh;
  width: 50vw;
}
