.dropdown {
  width: fit-content;
  border-radius: 10px;
  background: #eaecf0;
  .input {
    border-style: none;
    font-size: 14px;
    opacity: 0.4;
    font-weight: 500;
    &:disabled {
      border-radius: 10px;
    }
  }
}
