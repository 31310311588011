.detailsContainer {

  display: flex;
  min-width: 424px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);

  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  .heading{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .innerCard {
    // display: flex;
    padding: 16px;
    // align-items: flex-start;
    // gap: 16px;
    // align-self: stretch;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    border: 1px solid var(--Gray-200, #eaecf0);
    background: var(--Base-White, #fff);
  }
}
