@import "../../styles/variables.scss";

.outlet-count {
    border-right: solid 1.5px #878b8e;
}

.brandinfo-section {
    width: 23vw;
    height: max-content;
}

.w-100 {
    width: 100px;
}

.outlet-img {
    width: 100%;
    height: 150px;
}

.remove-img {
    top: 10px;
    right: 10px;
}
