.img-container {
  position: relative;
  width: 150px;
  height: 150px;
  padding: 10px;

  img {
    width: 150px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    transition: filter 0.3s ease;
  }

  .eye-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
  }

  .delete-icon {
    position: absolute;
    top: 10%;
    right: 5px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
    transition: background 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.7);
    }
  }


  &:hover {
    img {
      filter: brightness(50%);
    }

    .eye-icon {
      opacity: 1;
    }
  }
}
