.w-18 {
  width: 18px;
}

.ecom-table {
  tbody {
    tr {
      td {
        min-width: calc(100% / 6);
        &:last-of-type {
          width: min-content;
        }
      }
    }
  }
}

.mobility-table {
  tbody {
    tr {
      td {
        min-width: calc(100% / 9);
        &:last-of-type {
          width: min-content;
        }
      }
    }
  }
}
