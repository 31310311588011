* {
  box-sizing: border-box;
}

.loader {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 99999;
  left: 0;
}

.loader .s2 {
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: transparent;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader .s1 {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 50vh;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: transparent;
}

.loader .bigcon {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 50vh;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: transparent;
  animation: bigcon 2s infinite linear;
  animation-delay: 0.25s;
}

.loader .b {
  border-radius: 50%;
  position: absolute;
}

.loader .s {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: small 2s infinite ease;
  box-shadow: 0px 1px rgba(0, 0, 0, 0.2);
  background-color: #a58958;
}

.loader .s:nth-child(1) {
  top: 0%;
  left: 0%;
}

.loader .s:nth-child(2) {
  top: 0%;
  right: 0%;
}

.loader .s:nth-child(3) {
  right: 0%;
  bottom: 0%;
}

.loader .s:nth-child(4) {
  bottom: 0%;
  left: 0%;
}

.loader .big {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px #fff, 0px 0px 4px #fff;
  z-index: 1;
  background-color: #fff;
  animation: bigball 1s infinite linear;
}

.loader .sb1 {
  animation-delay: -1.75s;
}

.loader .sb6 {
  animation-delay: -1.5s;
}

.loader .sb2 {
  animation-delay: -1.25s;
}

.loader .sb7 {
  animation-delay: -1s;
}

.loader .sb3 {
  animation-delay: -0.75s;
}

.loader .sb8 {
  animation-delay: -0.5s;
}

.loader .sb4 {
  animation-delay: -0.25s;
}

.loader .sb5 {
  animation-delay: 0s;
}

@keyframes bigcon {
  0% {
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  100% {
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(405deg);
  }
}

@keyframes small {
  0% {
    transform: scale(1);
    background-color: #ffA500;
  }

  10% {
    transform: scale(1.3);
    background-color: #fff;
  }

  15% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
    background-color: #ffA500;
  }

  100% {
    transform: scale(1);
    background-color: #ffA500;
  }
}