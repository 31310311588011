.modal-title {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}

.modal-header {
    padding-bottom: 10px;
    border-bottom: solid thin #d2d2d2;
}
