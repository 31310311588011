.header{
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.table {
  thead {
    th:first-of-type{
      button{
        width: 20px;
        margin-right: 16px;
      }
    }
  }
  tbody {
    tr {
      td {
        &:first-of-type {
          width: 100%;
          // width: min-content;
        }
      }
    }
  }
}