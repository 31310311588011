.action {
  display: flex;
  gap: 8px;
}
.categoryContainer {
  display: grid;
  grid-template-columns: 0.3fr 0.2fr;

  .categoryImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.table {
  thead {
    th:first-of-type {
      button {
        width: 20px;
        margin-right: 16px;
      }
    }
  }
  tbody {
    tr {
      td {
        min-width: calc(100% / 3);
        &:first-of-type {
          width: min-content;
        }
        &:last-of-type {
          width: min-content;
        }
      }
    }
  }
}
