.ecom-table {
  tbody {
    tr {
      td {
        min-width: calc(100% / 7);
        &:last-of-type {
          width: min-content;
        }
      }
    }
  }
}

.mobility-table {
  tbody {
    tr {
      td {
        min-width: calc(100% / 7);
        &:last-of-type {
          width: min-content;
        }
      }
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 20ch;
  cursor: pointer;
}

.ellipsis:hover {
  overflow: visible;
  white-space: wrap;
}
