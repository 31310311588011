.card-info-section {
  margin-left: 24px;
}
.offer-title {
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0;
  padding: 5px 0;
  text-transform: capitalize;
  border-bottom: solid thin #eaecf0;
}

.action-btn-container > :first-child {
  margin-right: 5px;
}
