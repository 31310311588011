@import "../../../styles/variables";

.header {
  display: flex;
  justify-content: space-between;
  padding: 0px 80px;
  align-items: center;
  border-bottom: 1px solid $color-grey-light;
}

.profile-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.profile-name {
  width: 40px;
  height: 40px;
  background-color: $color-primary-transparent;
  border-radius: 50%;
  border: 1px solid #ddd;
  display: flex;
  cursor: pointer;
  p {
    color: $color-grey;
    padding: 10px;
    font-weight: 600;
    font-size: small;
    text-align: center;
  }
}
