@import "../../styles/variables";

table {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  thead {
    tr {
      td,
      th {
        color: #6d7175;
        background-color: #f9fafb;
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        padding: 16px 24px;
      }
    }
  }
  tbody {
    td {
      border-bottom: solid thin #eaecf0;
      color: #000000;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      padding: 16px 24px;
    }
  }
}
